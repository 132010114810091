import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar'; // Import your Navbar component
import Footer from './components/Footer';
import Home from './components/Home';     // Import the Home component
import About from './components/About';     // Import the About component
import Services from './components/Services'; // Import the Services component
import Contact from './components/Contact'; // Import the Contact component
import Register from './components/RegisterForm'; // Import the Register component

function App() {
  return (
    <>
    <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} /> {/* Add Home route */}
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/register" element={<Register />} />
        </Routes>
    </Router>
    <About/>
    <Footer/>
  
    </>
  );
}

export default App;
